import * as React from 'react';
import styled from '@emotion/styled';
import sizes from 'style/sizes';
import { headerHeightPc, headerHeightSp, mq } from 'style/variables';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import { TextSet } from 'components/molecules/textSet/';
import { JaHeading } from 'components/atoms/jaHeading/';
import { List } from 'components/atoms/list/';
import { Text } from 'components/atoms/text/';
import { EnHeading } from 'components/atoms/enHeading';
import { graphql } from 'gatsby';
import SEO from 'util/seo';
import { useEffect } from 'react';
import ParticleImageManager from 'components/organisms/particleCanvas/ParticleImageManager';
import { contentWrapper } from 'style/mixin';
// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

const PrivacyWrap = styled.div`
  ${contentWrapper}
`;

const TitleSection = styled.div`
  ${mq.onlypc} {
    padding-top: ${sizes.margin[180]};
    padding-bottom: ${sizes.margin[100]};
  }
  ${mq.onlysp} {
    padding-top: ${sizes.margin[155]};
    padding-bottom: ${sizes.margin[64]};
  }
`;

const PrivacySectionWrap = styled.div`
  padding-bottom: ${sizes.margin[100]};
`;

const PrivacySection = styled.div`
  ${mq.onlypc} {
    max-width: 700px;
    margin: 0 auto;
  }
  ul {
    margin-top: ${sizes.margin[32]};
  }
`;

const Pages: React.FC<IndexPageProps> = () => {
  useEffect(() => {
    ParticleImageManager.loadAll().then(() => {
      ParticleImageManager.generateAll().then(() => {
        ParticleImageManager.start();
      });
    });

    return () => {
      ParticleImageManager.delete();
    };
  }, []);

  return (
    <>
      <SEO title={'Privacy Policy'} en />
      <PrivacyWrap>
        <TitleSection>
          <EnHeading>
            Privacy <br className="only-sp" />
            Policy
          </EnHeading>
        </TitleSection>

        <PrivacySectionWrap>
          <PrivacySection>
            <TextSet>
              <JaHeading size={'middle'}>Policy Concerning Protection of Personal Information</JaHeading>
              <Text>
                We and our Group companies conduct digital design business, business related to web production, web
                content operation business, web service business, and business for supporting creative human resources,
                and process personal information obtained through such business activities (hereinafter referred to as
                "Personal Information").
              </Text>
              <Text>
                We recognize that it is our important social responsibility to protect said Personal Information in a
                reliable manner. Thus, we will keep in mind the following provisions and make utmost efforts to protect
                customers’ Personal Information in order to comply with laws and regulations, as well as guidelines and
                other rules established by the national government, concerning the protection of Personal Information
                and to handle Personal Information in a correct and honest manner.
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>1. Acquisition and purposes of use of Personal Information</JaHeading>

              <Text>
                We will ensure that all of our workers recognize the importance of the protection of Personal
                Information and collect and use the Personal Information by lawful and fair means within the scope of
                implementation of our business purposes. In addition, we will not use Personal Information for any
                purpose other than those specified herein. If we are required to use Personal Information for a purpose
                other than those specified herein, we will obtain prior approval for the new purpose from the person to
                whom the Personal Information pertains. Personal Information to be handled by us under the provisions of
                Article 18 of the Act on the Protection of Personal Information (hereinafter referred to as the "Act")
                shall be as follows:
              </Text>

              <JaHeading size={'xsmall'}>Personal Information that is handled in the course of business</JaHeading>

              <List>
                <li>To collaborate with customers and contractors for sales activities and operations</li>
                <li>For user surveys and interviews for service design, development, and production</li>
                <li>For trial use in the course of developing new services</li>
                <li>To host seminars and events, and to contact participants</li>
                <li>
                  For content to be communicated both within and outside the company, and for storage and utilization
                  thereof as record materials
                </li>
              </List>

              <JaHeading size={'xsmall'}>Personal Information related to recruitment activities</JaHeading>

              <List>
                <li>
                  To contact job seekers, check contracts, and review the application history in connection with our
                  (including our Group companies’) recruitment and screening activities
                </li>
              </List>

              <JaHeading size={'xsmall'}>Our employees’ information</JaHeading>

              <List>
                <li>
                  For personnel and labor management, operations management, health management, and security management
                  of our employees (including those of our Group companies)
                </li>
                <li>
                  For the purpose of use as set forth in the Act on the Use of Numbers to Identify a Specific Individual
                  in Administrative Procedures (specific personal information)
                </li>
              </List>

              <JaHeading size={'xsmall'}>Others</JaHeading>

              <List>
                <li>To respond to inquiries and requests we receive</li>
              </List>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>
                2. Provision and entrustment of Personal Information to third parties
              </JaHeading>

              <Text>
                We will not provide or entrust any Personal Information we possess to third parties without the consent
                of the person to whom the Personal Information pertains, except as provided by laws and regulations
                including the Act on the Protection of Personal Information.
                <br />
                When we entrust all or part of the handling of Personal Information to a third party with the consent of
                the person to whom the Personal Information pertains, we will conduct strict investigations of the third
                party in advance and exercise necessary and appropriate supervision of the third party so as to ensure
                that the security of the entrusted Personal Information will be assured.
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>
                3. Provision of non-personally identifiable information to third parties
              </JaHeading>

              <Text>
                As for the acquisition and storage of customers' environmental information, attribute information, and
                action history which are not personally identifiable, if we provide any of such information to a third
                party, we will only provide statistical information, and will not provide any Personal Information.
                <br />
                However, the limitation set forth above shall not apply to the following cases:
              </Text>

              <List>
                <li>
                  If we entrust all or part of the handling of Personal Information to said third party within the scope
                  necessary for achievement of the purposes of use
                </li>
                <li>
                  If Personal Information is provided to said third party as a result of a transfer of our business due
                  to a merger or similar events
                </li>
              </List>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>4. Joint use of Personal Information</JaHeading>

              <Text>
                We may share Personal Information we possess with our Group companies only to the extent necessary for
                our collaboration with them.
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>
                5. Compliance with laws and regulations concerning Personal Information
              </JaHeading>

              <Text>
                We will handle Personal Information in good faith in compliance with laws and regulations, as well as
                guidelines and other rules established by the national government (hereinafter collectively referred to
                as "Laws, etc."), concerning Personal Information and the Personal Information protection management
                system. We will also make efforts to remain aware of the Laws, etc. and will ensure that all workers
                engaged in our business (hereinafter referred to as "Workers") are aware of and comply with the Laws,
                etc.
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>
                6. Risk management policy and safety management measures relating to Personal Information
              </JaHeading>

              <Text>
                We will improve internal control and take organizational, personnel, physical, and technical safety
                management measures against risks of leakage of, loss of, or damage to Personal Information.
                Furthermore, we will conduct regular inspections and assess the external environment. If any violation,
                incident, or accident is detected, we will promptly report and correct it in accordance with laws and
                regulations and take preventive measures against defects. We will also provide safety education to our
                Workers.
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>
                7. Rights of and requests for disclosure, etc. of Personal Information
              </JaHeading>

              <Text>
                Any person can request us to disclose, correct, remove, suspend the use of, or delete his/her Personal
                Information through the procedure prescribed by us except in the cases listed below or where we are not
                legally obligated to comply with the request. However, we will not respond to any requests for
                disclosure if the person cannot identify himself/herself. In the case of disclosure, we may charge the
                person a prescribed disclosure fee.
              </Text>

              <List>
                <li>
                  Cases where the person’s or a third party's life, body, or property may be endangered if the existence
                  of the requested Personal Information is made clear
                </li>
                <li>
                  Cases where an illegal or unjust act may be encouraged or induced if the existence of the requested
                  Personal Information is made clear
                </li>
                <li>
                  Cases where national security may be threatened, the trusting relationship with other nations or
                  international organizations may be damaged, or any negotiation with other nations or international
                  organizations may be put at a disadvantage if the existence of the requested Personal Information is
                  made clear
                </li>
                <li>
                  Cases where the maintenance of public safety and order, including prevention, suppression, or
                  investigation of crimes, may be hampered if the existence of the requested Personal Information is
                  made clear
                </li>
              </List>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>8. Procedure for requesting disclosure, etc.</JaHeading>

              <Text>
                If you wish to request disclosure, correction, removal, suspension of use, or deletion of your Personal
                Information, please contact the personnel in charge of protection of Personal Information. We will then
                inform you of how to make the request, the procedure for identifying yourself, and the required
                documents.
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>9. Voluntary provision of Personal Information</JaHeading>

              <Text>
                Although provision of Personal Information to us is done on a voluntary basis, we may not be able to
                respond to inquiries or applications from a person who does not provide his/her Personal Information.
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>
                10. Acquisition of Personal Information in a method that cannot be easily discerned
              </JaHeading>

              <Text>
                We will not acquire any Personal Information in a method that cannot be easily discerned by the person
                to whom the Personal Information pertains.
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>11. Responses to complaints and consultations</JaHeading>

              <Text>
                We will always remain ready to respond appropriately to any complaint or consultation related to our
                handling of Personal Information, and act in an expedient and appropriate manner as soon as we receive
                such complaint or consultation.
              </Text>
            </TextSet>

            <TextSet>
              <JaHeading size={'small'}>
                12. Continuous improvement of our efforts to protect Personal Information
              </JaHeading>

              <Text>
                We will strive to continuously improve our efforts regarding the protection of Personal Information.
                Thus, this policy is subject to change without prior notice.
              </Text>
            </TextSet>

            <TextSet>
              <Text>
                Established on: August 13, 2008
                <br />
                Last revised on: April 1, 2022
                <br />
                FOURDIGIT Inc.
                <br />
                Ryo TAGUCHI, Representative Director, CEO
                <br />
                8-5-32 Akasaka, Minato-ku, Tokyo
              </Text>
            </TextSet>

            <TextSet>
              <Text>
                <b>[For inquiries related to handling of Personal Information]</b>
                <br />
                Personnel in charge of protection of Personal Information, FOURDIGIT Inc.
                <br />
                e-mail: privacy@4digit.jp
                <br />
                Reception hours: 10:00-17:00 on weekdays excluding year-end and New Year holidays and company-wide
                holidays
              </Text>
            </TextSet>
          </PrivacySection>
        </PrivacySectionWrap>
      </PrivacyWrap>

      <InquiryBlock en />
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query privacyQueryEn {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
